import './bootstrap';

import * as Sentry from '@sentry/vue';
import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { Mask } from 'maska';
import { vMaska } from 'maska/vue';
import money, { format } from 'v-money3';
import ApplicationLogo from './Components/ApplicationLogo.vue';
import VueGtag from 'vue-gtag';
import mixpanel from 'mixpanel-browser';
import VueEasyLightbox from 'vue-easy-lightbox';
import mitt from 'mitt';
import VueTailwindDatepicker from 'vue-tailwind-datepicker';
import '../css/rich-text.css';

const envMode = import.meta.env.MODE;

const appName =
	window.document.getElementsByTagName('title')[0]?.innerText || 'Rauzee';

window.mediaLibraryTranslations = {
	fileTypeNotAllowed: 'Você deve fazer upload de um arquivo do tipo',
	tooLarge: 'Arquivo muito grande, máximo',
	tooSmall: 'Arquivo muito pequeno, mínimo',
	tryAgain: 'por favor, tente enviar este arquivo novamente',
	somethingWentWrong: 'Algo deu errado ao enviar este arquivo',
	selectOrDrag: 'Selecione ou arraste os arquivos',
	selectOrDragMax: 'Selecione ou arraste no máximo {maxItems} {file}',
	file: { singular: 'arquivo', plural: 'arquivos' },
	anyImage: 'qualquer imagem',
	anyVideo: 'qualquer vídeo',
	goBack: 'Voltar',
	dropFile: 'Solte o arquivo para enviar',
	dragHere: 'Arraste o arquivo aqui',
	remove: 'Remover',
	download: 'Baixar',
};

window.emitter = mitt();

createInertiaApp({
	title: (title) => `${title} - ${appName}`,
	resolve: (name) => {
		// const pages = import.meta.glob('./Pages/**/*.vue', { eager: true })
		// return pages[`./Pages/${name}.vue`]

		return resolvePageComponent(
			`./Pages/${name}.vue`,
			import.meta.glob('./Pages/**/*.vue')
		);
	},
	setup({ el, App, props, plugin }) {
		const _app = createApp({ render: () => h(App, props) });

		const portalUrl = import.meta.env.VITE_PORTAL_URL;

		_app.provide('$portalUrl', portalUrl);
		_app.config.globalProperties.$portalUrl = portalUrl;

		if (envMode === 'production' && import.meta.env.VITE_SENTRY_DSN_PUBLIC) {
			Sentry.init({
				app: _app,
				dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
				environment: envMode,
				integrations: [],
				tracesSampleRate: 1.0,
				replaysSessionSampleRate: 0.1,
				replaysOnErrorSampleRate: 1.0,
				trackComponents: true,
			});
		}

		if (import.meta.env.VITE_MIXPANEL_TOKEN) {
			mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
				debug: envMode !== 'production',
				track_pageview: true,
				persistence: 'localStorage',
				track_links_timeout: 300,
				track_forms_timeout: 300,
				cross_site_cookie: true,
			});

			_app.provide('$mixpanel', mixpanel);
			_app.config.globalProperties.$mixpanel = mixpanel;
		}

		document.addEventListener('inertia:success', (event) => {
			mixpanel.track_pageview({
				page: event.detail.page.component,
				url: `${window.location.origin}${event.detail.page.url}`,
				path: event.detail.page.url,
			});
		});

		document.addEventListener('inertia:exception', (event) => {
			mixpanel.track('Exception', {
				exception: event.detail.error,
			});
		});

		_app.config.globalProperties.$envMode = envMode;

		_app.config.globalProperties.$filters = {
			maskIt(v, m) {
				const mask = new Mask({ mask: m });
				return mask.masked(v);
			},
			currency(v) {
				return format(v, {
					prefix: 'R$ ',
					suffix: '',
					thousands: '.',
					decimal: ',',
					precision: 2,
					disableNegative: false,
					disabled: false,
					min: null,
					max: null,
					allowBlank: false,
					minimumNumberOfCharacters: 0,
				});
			},
		};

		_app
			.use(plugin)
			.use(money)
			.use(VueGtag, {
				appName: 'Rauzee',
				enabled: envMode === 'production',
				pageTrackerEnabled: true,
				pageTrackerScreenviewEnabled: true,
				config: {
					id: import.meta.env.VITE_GA_ID,
				},
			})
			.use(VueEasyLightbox)
			.use(VueTailwindDatepicker)
			.directive('maska', vMaska)
			.mixin({ methods: { route } })
			.component('ApplicationLogo', ApplicationLogo);

		return _app.mount(el);
	},
});
